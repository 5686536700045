// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwkF7n-bc3mt5mshGwHsf_j9aMpAAc2Gc",
  authDomain: "ft9ja-hero-programme.firebaseapp.com",
  projectId: "ft9ja-hero-programme",
  storageBucket: "ft9ja-hero-programme.appspot.com",
  messagingSenderId: "727271633799",
  appId: "1:727271633799:web:2ce278cfcfb2e8acbc9922"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const storage = getStorage();