import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import MyDialog from "../components/MyDialog";

function WithdrawalRequests() {
  const [withdrawalRequests, setwithdrawalRequests] = useState([]);
  const [paypalWithdrawalRequests, setPaypalWithdrawalRequest] = useState([])
  const [withdrawalRequestsCategory, setWithdrawalRequestsCategory] = useState("")
  const [filteredBank, setFilteredBank] = useState("")
  const [filteredPaypal, setFilteredPaypal] = useState("")
  const [filterParams, setFilterParams] = useState("")
  const [message, setMessage] = useState("")
  const [transferCode, setTransferCode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [withdrawalId, setWithdrawlId] = useState("")
  // console.log(withdrawalRequests);

  useEffect(() => {
    const getWithdrawalRequests = async () => {
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-withdrawal-request/withdrawal-requests"
        );
        setwithdrawalRequests(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getPaypalWithdrawalRequests = async () => {
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-paypal-withdrawal-request/paypal-withdrawal-requests"
        );
        setPaypalWithdrawalRequest(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getWithdrawalRequests();
    getPaypalWithdrawalRequests();
  }, []);

  useEffect(() => {
    if (filterParams === "pending") {
      const filteredBank = withdrawalRequests.filter(r => r.status === "pending")
      const filterPaypal = paypalWithdrawalRequests.filter(r => r.status === "pending")
      setFilteredBank(filteredBank)
      setFilteredPaypal(filterPaypal)
    } else if (filterParams === "approved") {
      const filteredBank = withdrawalRequests.filter(r => r.status === "approved")
      const filterPaypal = paypalWithdrawalRequests.filter(r => r.status === "approved")
      setFilteredBank(filteredBank)
      setFilteredPaypal(filterPaypal)
    } else if (filterParams === "not approved") {
      const filteredBank = withdrawalRequests.filter(r => r.status === "not approved")
      const filterPaypal = paypalWithdrawalRequests.filter(r => r.status === "not approved")
      setFilteredBank(filteredBank)
      setFilteredPaypal(filterPaypal)
    }
  }, [filterParams, withdrawalRequests, paypalWithdrawalRequests])


  const createRecipient = async (r) => {
    setIsOpen(true)
    setMessage("Creating Recipient, Please Wait...");
    try {
      axios({
        method: "POST",
        url: "https://api.paystack.co/transferrecipient",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
          "Content-Type": "application/json",
        },
        data: {
          type: "nuban",
          name: r.acctName,
          account_number: r.acctNum,
          bank_code: r.bankCode,
          currency: "NGN",
        },
      })
        .then((response) => {
          console.log(response.data.data.recipient_code);
          setMessage(response.data.message);
          transferMoney(
            r,
            response.data.data.recipient_code
          );
        })
        .catch((error) => {
          console.error(error);
          setMessage(
            "Couldn't create a recipient for this payout, please check the account details and try again or refresh the page and try again"
          );
        });
    } catch (error) { }
  };

  const transferMoney = async (
    userDetails,
    recipientCodeForTransfer
  ) => {
    // console.log(i);
    setMessage("Initiating Transfer, Please Wait...");
    const id = uuidv4();
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
      "Content-Type": "application/json",
    };

    const url = "https://api.paystack.co/transfer";

    const data = JSON.stringify({
      source: "balance",
      amount: userDetails.amount * 100,
      reference: id,
      recipient: recipientCodeForTransfer,
      reason: "Commission",
    });

    try {
      axios
        .post(url, data, { headers })
        .then((response) => {
          console.log(response);
          setTransferCode(response.data.data.transfer_code);
          setWithdrawlId(userDetails._id)
          setMessage(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          setMessage(error.response.data.message);
          // alert(error.response.data.message);
        });
    } catch (error) { }
  };

  function noApprove(params) {
    axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-withdrawal-request/withdrawal-requests/${params}`, {
      status: "declined"
    }).then(res => {
      console.log(res)
      alert("Request status has been updated to not declined...")
      window.location.reload()
    }).catch ((err) => {
      console.log(err)
    })
  }

  function paypalApprove(params) {
    axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-paypal-withdrawal-request/paypal-withdrawal-requests/${params}`, {
      status: "approved"
    }).then(res => {
      console.log(res)
      alert("Request status has been updated to not approved...")
      window.location.reload()
    }).catch ((err) => {
      console.log(err)
    })
    
  }

  function paypalNoApprove(params) {
    axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-paypal-withdrawal-request/paypal-withdrawal-requests/${params}`, {
      status: "declined"
    }).then(res => {
      console.log(res)
      alert("Request status has been updated to not declined...")
      window.location.reload()
    }).catch ((err) => {
      console.log(err)
    })
  }

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602] text-center">
            Choose Withdrawal Requests
          </h1>
          <div className="flex flex-col mt-10 mb-10">
            <label htmlFor="" className="mb-2">
              Withdrawal Category
            </label>
            <select
              name=""
              id=""
              className="px-3 py-1.5 border border-black outline-none"
              value={withdrawalRequestsCategory}
              onChange={(e) => setWithdrawalRequestsCategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select Reward Category
              </option>
              <option value="bank">Bank Account</option>
              <option value="paypal">Paypal</option>
            </select>
          </div>
          {withdrawalRequestsCategory === "bank" && <div className="w-[100%] md:w-[90%] flex items-center flex-col gap-5" >
            <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10 mx-auto'>
              <div>
                <p>Filter by Status</p>
                <select
                  name=""
                  id=""
                  className="px-3 py-1.5 border border-black outline-none"
                  value={withdrawalRequestsCategory}
                  onChange={(e) => setFilterParams(e.target.value)}
                >
                  <option value="">
                    Select Status
                  </option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="not approved">Not Approved</option>
                </select>
              </div>
            </div>
            <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
              {filteredBank.length > 0 ? filteredBank.map(request => (<div
                key={request._id}
                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                <h1>User Email: {request.userEmail}</h1>
                <p>Bank: {request.bank}</p>
                <p>Account Name: {request.acctName}</p>
                <p>Account Number: {request.acctNum}</p>
                <p>Amount: {request.amount}</p>
                <p>Status: {request.status}</p>
                <button disabled={request.status === "approved" || request.status === "declined"}  className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">
                  Approve
                </button>
                <button onClick={() => noApprove(request._id)} disabled={request.status === "approved" || request.status === "declined"}  className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">
                  Don't Approve
                </button>
              </div>)) : withdrawalRequests?.map((request) => (
                <div
                  key={request._id}
                  className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                >
                  <h1>User Email: {request.userEmail}</h1>
                  <p>Bank: {request.bank}</p>
                  <p>Account Name: {request.acctName}</p>
                  <p>Account Number: {request.acctNum}</p>
                  <p>Amount: {request.amount}</p>
                  <p>Status: {request.status}</p>
                  <button disabled={request.status === "approved" || request.status === "declined"} onClick={() => createRecipient(request)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">
                    Approve
                  </button>
                  <button onClick={() => noApprove(request._id)} disabled={request.status === "approved" || request.status === "declined"} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px]  disabled:bg-gray-800">
                    Don't Approve
                  </button>
                </div>
              ))}
            </div>
          </div>}

          {withdrawalRequestsCategory === "paypal" && <div className="w-[100%] md:w-[90%] flex items-center flex-col gap-5">
            <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10 mx-auto'>
              <div>
                <p>Filter by Status</p>
                <select
                  name=""
                  id=""
                  className="px-3 py-1.5 border border-black outline-none"
                  value={withdrawalRequestsCategory}
                  onChange={(e) => setFilterParams(e.target.value)}
                >
                  <option value="">
                    Select Status
                  </option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="not approved">Not Approved</option>
                </select>
              </div>
            </div>
            <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
              {filteredPaypal.length > 0 ? filteredPaypal.map(request => (<div
                key={request._id}
                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                <h1>User Email: {request.userEmail}</h1>
                <p>Paypal Email: {request.paypalEmail}</p>
                <p>Amount: {request.amount}</p>
                <p>Status: {request.status}</p>
                <button disabled={request.status === "approved" || request.status === "declined"} className="bg-[#359602] text-white text-lg px-2 py-1 my-3 rounded-[10px] disabled:bg-gray-600">
                  Approve
                </button>
                <button disabled={request.status === "approved" || request.status === "declined"} className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] disabled:bg-gray-800">
                  Not Approve
                </button>
              </div>)) : paypalWithdrawalRequests?.map(request => (<div
                key={request._id}
                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                <h1>User Email: {request.userEmail}</h1>
                <p>Paypal Email: {request.paypalEmail}</p>
                <p>Amount: {request.amount}</p>
                <p>Status: {request.status}</p>
                <button disabled={request.status === "approved" || request.status === "declined"} onClick={() => paypalApprove(request._id)} className="bg-[#359602] text-white text-lg px-2 py-1 my-3 rounded-[10px] disabled:bg-gray-600">
                  Approve
                </button>
                <button disabled={request.status === "approved" || request.status === "declined"} onClick={() => paypalNoApprove(request._id)} className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] disabled:bg-gray-800">
                  Not Approve
                </button>
              </div>))}
            </div>
          </div>}

          <MyDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            message={message}
            setMessage={setMessage}
            transferCode={transferCode}
            setTransferCode={setTransferCode}
            withdrawalId={withdrawalId}
          />

        </div>
      </div>
    </div>
  );
}

export default WithdrawalRequests;
