import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import axios from 'axios'
import { read, utils } from 'xlsx';
import { useDropzone } from 'react-dropzone';
import SendingDialog from '../components/SendingDialog';


function Notification() {
    const [notificationMessage, setNotificationMessage] = useState("")
    const [uploadedData, setUploadedData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    // console.log(filteredUsers)

    function handleSend() {
        setIsOpen(true)
        if (notificationMessage !== "" && uploadedData.length > 0) {

            const newArray = uploadedData.map(({ id, Email }) => {
                const notif = notificationMessage
                return { userEmail: Email, message: notif }
            })

            console.log(newArray)

            axios
                .post(
                    "https://hero-backend-july10-72654a654b93.herokuapp.com/api/notification/notifications", newArray
                )
                .then((res) => {
                    console.log(res)
                    setIsOpen(false)
                    if (res.status === 200) {
                        setIsOpen(false)
                        alert("Notification sent...")
                        window.location.reload()
                    } else {
                        alert("Error sending notification...")
                    }
                    window.location.reload()
                    setNotificationMessage("")
                });
        } else {
            alert("Make sure all fields have been filled out.")
            window.location.reload()
        }
    }


    const onDrop = (acceptedFiles) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryData = event.target.result;
            const workbook = read(binaryData, { type: 'binary' });

            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];

            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const headers = jsonData[0];
            const data = jsonData.filter(dat => dat.length > 1).slice(1);
            const dataArray = data.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });
            setUploadedData(dataArray);
            // console.log(dataArray); // Pass the data to the parent component
        };

        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
                    <h1 className="mb-5 text-4xl font-bold text-[#359602]">
                        Send Notification
                    </h1>

                    <div  {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the Excel file here...</p>
                        ) : (
                            <div className='border-black p-20 border-dashed border-[3px]'>
                                <p>Drag 'n' drop an Excel file here, or click to select the file</p>
                            </div>
                        )}
                        {uploadedData.length > 0 && (
                            <div className='mt-10'>
                                <h4 className='font-bold text-2xl mb-2'>Uploaded Emails:</h4>
                                <div className='grid grid-cols-2 gap-2'>{uploadedData.map((data, index) => (
                                    <p key={data.Email}><span className='font-semibold'>{index + 1}.</span> <span>{data.Email}</span></p>
                                ))}</div>

                            </div>
                        )}
                    </div>
                    {uploadedData.length > 0 &&
                        <div className='mt-10'>
                            <p>Notification Message</p>
                            <input className="px-3 py-1.5 border border-black outline-none w-[100%] mb-4" onChange={(e) => setNotificationMessage(e.target.value)} placeholder='Eg. New Learning Video Available' type="text" />
                            <button className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]" onClick={handleSend}>Send</button>
                        </div>
                    }
                    <SendingDialog
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />

                </div>
            </div>
        </div>
    )
}

export default Notification