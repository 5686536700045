import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import axios from 'axios';

function QuizResults() {
    const [quizResults, setQuizResults] = useState([]);
    const [filteredQuizResults, setFilteredQuizResults] = useState([])
    const [filterParams, setFilterParams] = useState({
        month: "",
        year: "",
        email: "",
        score: ""
    })
    const [filterBy, setFilterBy] = useState("")
    console.log(filteredQuizResults);
    console.log(filterParams);

    useEffect(() => {
        const getQuizzes = async () => {
            try {
                const res = await axios.get("https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-quiz-result/user-quiz-results");
                setQuizResults(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        getQuizzes();
    }, []);

    useEffect(() => {
        if (filterParams.month === "" && filterParams.year === "" && filterParams.score === "" && filterParams.email !== "") {
            const filteredResults = quizResults.filter(i => i.userEmail === filterParams.email)
            setFilteredQuizResults(filteredResults)
        } else if (filterParams.month !== "" && filterParams.year !== "" && filterParams.score === "") {
            const filteredResults = quizResults.filter(i => i.quizMonth === filterParams.month && i.quizYear === filterParams.year)
            setFilteredQuizResults(filteredResults)
        } else if (filterParams.month !== "" && filterParams.year !== "" && filterParams.score !== "") {
            if (filterParams.score === "great-60") {
                const filteredResults = quizResults.filter(i => i.quizMonth === filterParams.month && i.quizYear === filterParams.year && Number(i.score) > 60)
                setFilteredQuizResults(filteredResults)
            } else {
                const filteredResults = quizResults.filter(i => i.quizMonth === filterParams.month && i.quizYear === filterParams.year && Number(i.score) < 60)
                setFilteredQuizResults(filteredResults)
            }
        }
    }, [filterParams.month, quizResults, filterParams.year, filterParams.email, filterParams.score])
    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
                    <h1 className="mb-5 text-4xl font-bold text-[#359602]">
                        User Quiz Results
                    </h1>
                    <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10'>
                        <div>
                            <p>Choose filter option</p>
                            <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterBy(e.target.value)}>
                                <option value="">Filter By:</option>
                                <option value="email">Email</option>
                                <option value="year-month">Year and Month</option>
                            </select>
                        </div>
                        {filterBy === "year-month" && <div className='flex flex-col lg:flex-row items-center lg:gap-10'>
                            <div>
                                <p>Filter by month</p>
                                <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, month: e.target.value })}>
                                    <option value="">Select Month</option>
                                    <option value="Jan">January</option>
                                    <option value="Feb">February</option>
                                    <option value="Mar">March</option>
                                    <option value="Apr">April</option>
                                    <option value="May">May</option>
                                    <option value="Jun">June</option>
                                    <option value="Jul">July</option>
                                    <option value="Aug">August</option>
                                    <option value="Sep">September</option>
                                    <option value="Oct">October</option>
                                    <option value="Nov">November</option>
                                    <option value="Dec">December</option>
                                </select>
                            </div>
                            <div>
                                <p>Filter by Year</p>
                                <input type="text" placeholder='Eg. 2023' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, year: e.target.value })} />
                            </div>
                            {filterParams.year.length > 3 && filterParams.month !== "" && <div>
                                <p>Filter by Score</p>
                                <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, score: e.target.value })}>
                                    <option value="">Select score range</option>
                                    <option value="great-60">Greater than 60%</option>
                                    <option value="less-60">Less than 60%</option>
                                </select>
                            </div>}
                        </div>}
                        {filterBy === "email" && <div>
                            <p>Filter by Email</p>
                            <input type="text" placeholder='Eg. test0@test0.com' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, email: e.target.value })} />
                        </div>}

                    </div>
                    <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
                        {filteredQuizResults.length > 0 ? filteredQuizResults.map(quiz => (<div
                            key={quiz._id}
                            className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                        >
                            <h1 className="font-bold text-lg">
                                User: <span className="font-normal">{quiz.userEmail}</span>
                            </h1>
                            <p className="font-bold">
                                Attempts: <span className="font-normal">{quiz.attempts}</span>
                            </p>
                            <p className="font-bold">
                                Score: <span className="font-normal">{quiz.score}</span>
                            </p>
                            <p className="font-bold">
                                Quiz Month: <span className="font-normal">{quiz.quizMonth}</span>
                            </p>
                            <p className="font-bold">
                                Quiz Year: <span className="font-normal">{quiz.quizYear}</span>
                            </p>
                            <p className="font-bold">
                                Quiz Title: <span className="font-normal">{quiz.quizTitle}</span>
                            </p>
                            <p className="font-bold">
                                Date: <span className="font-normal">{quiz.date}</span>
                            </p>
                        </div>)) : quizResults?.map((quiz) => (
                            <div
                                key={quiz._id}
                                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                            >
                                <h1 className="font-bold text-lg">
                                    User: <span className="font-normal">{quiz.userEmail}</span>
                                </h1>
                                <p className="font-bold">
                                    Attempts: <span className="font-normal">{quiz.attempts}</span>
                                </p>
                                <p className="font-bold">
                                    Score: <span className="font-normal">{quiz.score}</span>
                                </p>
                                <p className="font-bold">
                                    Quiz Month: <span className="font-normal">{quiz.quizMonth}</span>
                                </p>
                                <p className="font-bold">
                                    Quiz Year: <span className="font-normal">{quiz.quizYear}</span>
                                </p>
                                <p className="font-bold">
                                    Quiz Title: <span className="font-normal">{quiz.quizTitle}</span>
                                </p>
                                <p className="font-bold">
                                    Date: <span className="font-normal">{quiz.date}</span>
                                </p>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizResults