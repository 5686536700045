import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const user = {
      email: e.target[0].value,
      password: e.target[1].value,
    };

    try {
      const res = await axios.post(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth/login",
        user
      );
      console.log(res.data);
      localStorage.setItem("ft9jaPromo", res.data.isAdmin);
      navigate("/home");
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };
  return (
    <div className="bg-[#FFA500] w-full h-[100vh] flex items-center justify-center">
      <div className="bg-black w-[90%] sm:w-[50%] h-[50%] flex flex-col items-center justify-center rounded-[15px]">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col gap-5 w-[90%] sm:w-[50%]"
        >
          <div>
            <input
              type="email"
              placeholder="Email"
              className="px-1.5 py-2.5 w-[100%] outline-none"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              className="px-1.5 py-2.5 w-[100%] outline-none"
            />
          </div>
          <button disabled={loading} className="bg-[#359602] disabled:bg-gray-500 text-xl text-white py-2 hover:bg-white hover:text-[#1f2937]">
            {loading ? "Please wait..." : "Login"}
          </button>
        </form>
        {error && (
          <p className="text-red-500 mt-3">Check credentials and try again</p>
        )}

        {/* <Link to="/">
          <p className="text-white mt-5">Register Here</p>
        </Link> */}
      </div>
    </div>
  );
}

export default Login;
