import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";

function dateConverter(date) {
  const dateStr = date;
  const dateParts = dateStr.split("/");
  const mainDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = mainDate.toLocaleDateString("en-US", options);

  //   console.log(formattedDate);
  return formattedDate;
}

function Advocacy() {
  const [advocates, setAdvocates] = useState([]);
  const [filterBy, setFilterBy] = useState("")
  const [filterParams, setFilterParams] = useState({
    month: "",
    year: "",
    email: "",
  })
  const [filteredAdvocacy, setFilteredAdvocacy] = useState([])
  // console.log(filteredAdvocacy);

  useEffect(() => {
    const getAdvocates = async () => {
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-advocate/user-advocacies"
        );
        // console.log(res.data)
        setAdvocates(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAdvocates();
  }, []);

  useEffect(() => {
    if (filterParams.email !== "") {
      const filteredResults = advocates.filter(i => i.userEmail === filterParams.email)
      setFilteredAdvocacy(filteredResults)
    } else if (filterParams.month !== "" && filterParams.year !== "") {
      // const split = proofOfActivities[0].date.split("/")
      // console.log(split[1])
      const filteredResults = advocates.filter(i => i.date.split("/")[1] === filterParams.month && i.date.split("/")[2] === filterParams.year)
      setFilteredAdvocacy(filteredResults)
    }
  }, [filterParams.email, advocates, filterParams.month, filterParams.year])

  const handleDelete = async (pathId) => {
    try {
      const res = await axios.delete(
        `https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-advocate/user-advocacies/${pathId}`
      );
      alert(res.data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Advocates
          </h1>
          <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10'>
            <div>
              <p>Choose filter option</p>
              <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterBy(e.target.value)}>
                <option value="">Filter By:</option>
                <option value="email">Email</option>
                <option value="year-month">Year and Month</option>
              </select>
            </div>
            {filterBy === "year-month" && <div className='flex flex-col lg:flex-row items-center lg:gap-10'>
              <div>
                <p>Filter by month</p>
                <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, month: e.target.value })}>
                  <option value="">Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div>
                <p>Filter by Year</p>
                <input type="text" placeholder='Eg. 2023' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, year: e.target.value })} />
              </div>

            </div>}
            {filterBy === "email" && <div>
              <p>Filter by Email</p>
              <input type="text" placeholder='Eg. test0@test0.com' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, email: e.target.value })} />
            </div>}
          </div>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {filteredAdvocacy.length > 0 ? filteredAdvocacy.map(advocacy => (<div
              key={advocacy._id}
              className="flex gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
            >
              <video
                controls
                className="w-[150px] h-[150px] rounded-lg"
                src={advocacy.fileUrl}
                poster={advocacy.fileUrl}
                alt=""
              />
              <div className="w-[80%] relative">
                <h1 className="font-bold text-lg">
                  Uploaded by:{" "}
                  <span className="font-normal">{advocacy.userEmail}</span>
                </h1>
                <p className="font-bold">
                  Upload date:{" "}
                  <span className="font-normal">
                    {dateConverter(advocacy.date)}
                  </span>
                </p>
                <p className="font-bold">
                  File Name:{" "}
                  <span className="font-normal">{advocacy.name}</span>
                </p>
                <button
                  onClick={() => handleDelete(advocacy._id)}
                  className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] absolute right-0 bottom-0"
                >
                  Delete
                </button>
              </div>
            </div>)) : filteredAdvocacy.length < 1 && filterBy === "email" && filterParams.email !== "" ? <div></div> : filteredAdvocacy.length < 1 && filterBy === "year-month" && filterParams.month !== "" && filterParams.year !== "" ? <div></div> : advocates?.map((advocacy) => (
              <div
                key={advocacy._id}
                className="flex gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                <video
                  controls
                  className="w-[150px] h-[150px] rounded-lg"
                  src={advocacy.fileUrl}
                  poster={advocacy.fileUrl}
                  alt=""
                />
                <div className="w-[80%] relative">
                  <h1 className="font-bold text-lg">
                    Uploaded by:{" "}
                    <span className="font-normal">{advocacy.userEmail}</span>
                  </h1>
                  <p className="font-bold">
                    Upload date:{" "}
                    <span className="font-normal">
                      {dateConverter(advocacy.date)}
                    </span>
                  </p>
                  <p className="font-bold">
                    File Name:{" "}
                    <span className="font-normal">{advocacy.name}</span>
                  </p>
                  <button
                    onClick={() => handleDelete(advocacy._id)}
                    className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] absolute right-0 bottom-0"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advocacy;
