import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Quiz from "./pages/Quiz";
import Learning from "./pages/Learning";
import Activities from "./pages/Activities";
import Benefit from "./pages/Benefit";
import AddLearning from "./pages/AddLearning";
import AddBenefit from "./pages/AddBenefit";
import AddActivity from "./pages/AddActivity";
import AddQuiz from "./pages/AddQuiz";
import SingleQuiz from "./pages/SingleQuiz";
import Login from "./pages/Login";
import Advocacy from "./pages/Advocacy";
import UserActivitiesProof from "./pages/UserActivitiesProof";
import RewardUser from "./pages/RewardUser";
import Reward from "./pages/Reward";
import WithdrawalRequests from "./pages/WithdrawalRequests";
import QuizResults from "./pages/QuizResults";
import Notification from "./pages/Notification";
import Rewards from "./pages/Rewards";
import Users from "./pages/Users";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/quiz/add-quiz" element={<AddQuiz />} />
          <Route path="/quiz/:id" element={<SingleQuiz />} />
          <Route path="/quiz/quiz-results" element={<QuizResults />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/learning/add-learning" element={<AddLearning />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/activities/add-activities" element={<AddActivity />} />
          <Route
            path="/activities/user-activities-proof"
            element={<UserActivitiesProof />}
          />
          <Route path="/benefit" element={<Benefit />} />
          <Route path="/benefit/add-benefit" element={<AddBenefit />} />
          <Route path="/benefit/reward-user" element={<RewardUser />} />
          <Route path="/benefit/rewards" element={<Rewards />} />
          <Route path="/benefit/withdrawal-requests" element={<WithdrawalRequests />} />
          <Route path="/advocacy" element={<Advocacy />} />
          <Route path="/reward/:user" element={<Reward />} />
          <Route exact path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
