import React from 'react'
import Sidebar from '../components/Sidebar'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'

function Users() {
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [filterBy, setFilterBy] = useState("")

    useEffect(() => {
        const getLearnings = async () => {
            try {
                const res = await axios.get(
                    "https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth"
                );
                console.log(res.data)
                setUsers(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        getLearnings();
    }, [])

    useEffect(() => {
        const filteredResults = users.filter(i => i.email === filterBy)
        setFilteredUsers(filteredResults)
    }, [users, filterBy])

    async function handleDelete(params) {
        try {
            const res = await axios.delete(
                `https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth/users/${params}`
            );
            alert(res.data);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
                    <h1 className="mb-5 text-4xl font-bold text-[#359602]">
                        All Users
                    </h1>
                    <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10'>
                        <div>
                            <p>Filter by email</p>
                            <input type='text' className="px-3 py-1.5 border border-black outline-none" onChange={e => setFilterBy(e.target.value)} />
                        </div>
                    </div>
                    <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
                        {filteredUsers.length > 0 ? filteredUsers.map(user => (
                            <div
                                key={user._id}
                                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                            >
                                <h1>Email: <span>{user.email}</span></h1>
                                <h1>First Name: <span>{user.firstName}</span></h1>
                                <h1>Last Name: <span>{user.lastName}</span></h1>
                                <button className='bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px]' onClick={() => handleDelete(user.email)}>Delete</button>
                            </div>
                        )) : filteredUsers.length < 1 && filterBy.length > 0 ? <div></div> : users?.map(user => (
                            <div
                                key={user._id}
                                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                            >
                                <h1>Email: <span>{user.email}</span></h1>
                                <h1>First Name: <span>{user.firstName}</span></h1>
                                <h1>Last Name: <span>{user.lastName}</span></h1>
                                <button className='bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px]' onClick={() => handleDelete(user.email)}>Delete</button>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users