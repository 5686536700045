import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

function Quiz() {
  const [quizzes, setQuizzes] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([])
  const [filterParams, setFilterParams] = useState({
    month: "",
    year: "",
  })
  // console.log(filteredQuizzes);

  useEffect(() => {
    const getQuizzes = async () => {
      try {
        const res = await axios.get("https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes");
        setQuizzes(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getQuizzes();
  }, []);

  useEffect(() => {
    const filteredResults = quizzes.filter(i => i.month === filterParams.month && i.year === filterParams.year)
    setFilteredQuizzes(filteredResults)

  }, [filterParams.month, quizzes, filterParams.year])

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Quizzes
          </h1>
          <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10'>
            <div>
              <p>Filter by month</p>
              <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, month: e.target.value })}>
                <option value="">Select Month</option>
                <option value="Jan">January</option>
                <option value="Feb">February</option>
                <option value="Mar">March</option>
                <option value="Apr">April</option>
                <option value="May">May</option>
                <option value="Jun">June</option>
                <option value="Jul">July</option>
                <option value="Aug">August</option>
                <option value="Sep">September</option>
                <option value="Oct">October</option>
                <option value="Nov">November</option>
                <option value="Dec">December</option>
              </select>
            </div>
            <div>
              <p>Filter by year</p>
              <input type="text" placeholder='Eg. 2023' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, year: e.target.value })} />
            </div>
          </div>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {filteredQuizzes.length > 0 ? filteredQuizzes.map((quiz, index) => (<div
              key={quiz._id}
              className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
            >
              <p>{index + 1}</p>
              <h1 className="font-bold text-lg">
                Title: <span className="font-normal">{quiz.title}</span>
              </h1>
              <p className="font-bold">
                Year: <span className="font-normal">{quiz.year}</span>
              </p>
              <p className="font-bold">
                Month: <span className="font-normal">{quiz.month}</span>
              </p>
              <p className="font-bold">
                Question: <span className="font-normal">{quiz.question}</span>
              </p>
              <Link to={`/quiz/${quiz._id}`}>
                <button className="bg-[#359602] w-[100%] text-white text-lg px-2 py-1 my-3 rounded-[10px]">
                  View
                </button>
              </Link>
            </div>)) : filteredQuizzes.length < 1 && filterParams.month !== "" && filterParams.year !== "" ? <div></div> : quizzes?.map((quiz, index) => (
              <div
                key={quiz._id}
                className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                <p>{index + 1}</p>
                <h1 className="font-bold text-lg">
                  Title: <span className="font-normal">{quiz.title}</span>
                </h1>
                <p className="font-bold">
                  Year: <span className="font-normal">{quiz.year}</span>
                </p>
                <p className="font-bold">
                  Month: <span className="font-normal">{quiz.month}</span>
                </p>
                <p className="font-bold">
                  Question: <span className="font-normal">{quiz.question}</span>
                </p>
                <Link to={`/quiz/${quiz._id}`}>
                  <button className="bg-[#359602] w-[100%] text-white text-lg px-2 py-1 my-3 rounded-[10px]">
                    View
                  </button>
                </Link>
              </div>
            ))}
          </div>
          <Link to="/quiz/add-quiz">
            <button className=" md:hidden fixed bottom-5 left-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Add Quiz
            </button>
          </Link>
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
