import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import axios from "axios";

function SingleQuiz() {
  const [quiz, setQuiz] = useState({});
  //   console.log(quiz)

  const location = useLocation();
  const pathId = location.pathname.split("/")[2];
  //   console.log(pathId);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes/${pathId}`
        );
        setQuiz(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [pathId]);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes/${pathId}`);
      navigate("/quiz");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[100vh] md:pl-0 pl-5">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className=" relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center pt-5">
          <div className="w-[100%] md:w-[70%] flex flex-col items-start gap-5">
            <h1 className="font-bold">
              Year: <span className="font-normal">{quiz?.year}</span>
            </h1>
            <h1 className="font-bold">
              Month: <span className="font-normal">{quiz?.month}</span>
            </h1>
            <h1 className="font-bold">
              Title: <span className="font-normal">{quiz?.title}</span>
            </h1>
            <h1 className="font-bold">
              Question: <span className="font-normal">{quiz?.question}</span>
            </h1>
            <h1 className="font-bold">
              Answer: <span className="font-normal">{quiz?.answer}</span>
            </h1>
            <div>
              <h1 className="font-bold">Choices:</h1>
              <ul className="flex flex-row items-center gap-5">
                {quiz?.choices?.map((choice, index) => (
                  <li key={index}>
                    {index + 1}. {choice}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex gap-5">
              <Link to={`/quiz/add-quiz?edit=2`} state={quiz}>
                <button className="bg-[#359602] text-white text-lg px-2 py-1 my-3 rounded-[10px]">
                  Edit
                </button>
              </Link>
              <button
                onClick={handleDelete}
                className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px]"
              >
                Delete
              </button>
            </div>
            <Link to="/quiz/add-quiz">
              <button className=" md:hidden fixed bottom-5 left-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
                Add Quiz
              </button>
            </Link>
            <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
            <Link to="/">
              <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
                Log out
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleQuiz;
