import { Dialog } from "@headlessui/react";
import axios from "axios";
import { useState } from "react";

function MyDialog({
    isOpen,
    setIsOpen,
    message,
    transferCode,
    setTransferCode,
    withdrawalId,
    setMessage,
}) {
    const [otp, setOtp] = useState("");

    const payWithOtp = () => {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
            "Content-Type": "application/json",
        };

        const data = JSON.stringify({
            transfer_code: transferCode,
            otp: otp,
        });

        setMessage("Processing OTP, Please Wait...");

        try {
            axios
                .post("https://api.paystack.co/transfer/finalize_transfer", data, {
                    headers,
                })
                .then((response) => {
                    console.log(response);
                    setMessage(response.data.message);
                    setTransferCode("");
                    onApprove(withdrawalId)
                })
                .catch((error) => {
                    setMessage(error.response.data.message);
                });
        } catch (error) { }
    };

    function onApprove(params) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/hero-stipend-benefit/hero-stipends-rewards/${params}`, {
            status: "approved"
        }).then(res => {
            console.log(res)
            alert("Hero stipend for this user has been updated to approved...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-50"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
                {/* The actual dialog panel  */}
                <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-4">
                    <Dialog.Title className="text-center text-lg font-medium">
                        {message}
                    </Dialog.Title>
                    {transferCode !== "" && (
                        <div className="flex items-center gap-2 mt-3">
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                onChange={(e) => setOtp(e.target.value)}
                                className="border outline-none border-black rounded-lg px-2 py-1"
                            />
                            <button
                                className="text-white bg-[#359602] text-lg py-0.5 px-2 rounded-lg disabled:bg-gray-500"
                                disabled={otp.length < 6}
                                onClick={payWithOtp}
                            >
                                Pay with OTP
                            </button>
                        </div>
                    )}
                    <button
                        className="text-white bg-[#359602] text-lg py-0.5 px-2 rounded-lg border border-[#359602] mt-5"
                        onClick={() => {
                            setIsOpen(false);
                            window.location.reload();
                        }}
                    >
                        Close
                    </button>

                    {/* ... */}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}

export default MyDialog;
