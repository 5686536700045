import React from "react";
import { Quiz, Learning, Activities, Benefit, Advocate, Notification } from "../assets";
import Users from "../assets/users.png" 
import { Link } from "react-router-dom";

const categories = [
  {
    id: "1",
    title: "Quiz",
    logo: Quiz,
    link: "/quiz",
  },
  {
    id: "2",
    title: "Learning",
    logo: Learning,
    link: "/learning",
  },
  {
    id: "3",
    title: "Activities",
    logo: Activities,
    link: "/activities",
  },
  {
    id: "4",
    title: "Benefit",
    logo: Benefit,
    link: "/benefit",
  },
  {
    id: "5",
    title: "Advocacy",
    logo: Advocate,
    link: "/advocacy",
  },
  {
    id: "6",
    title: "Notification",
    logo: Notification,
    link: "/notification",
  },
  {
    id: "7",
    title: "Users",
    logo: Users,
    link: "/users",
  },
];

function Home() {
  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="grid grid-cols-3 gap-5 w-[90%] md:w-[50%]">
        {categories.map((cat) => (
          <Link to={cat.link} key={cat.id}>
            <div
              className="flex flex-col items-center md:h-[150px] h-[100px] justify-between border border-[#359602] rounded-md py-5 cursor-pointer"
              
            >
              <img src={cat.logo} alt={cat.title} className="w-[25%]" />
              <h1 className="text-lg font-semibold">{cat.title}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Home;
