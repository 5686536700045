import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

function RewardUser() {
  const [userEmail, setUserEmail] = useState("");
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth/`
        );
        console.log(res.data);
        setUserDetails(res.data.filter(res => res.email === userEmail));
      } catch (error) {
        console.log(error)
      }
    };
    getUser();
  }, [userEmail]);

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            Find the user you want to reward
          </h1>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-wrap gap-5">
            <div className="flex flex-col">
              <label htmlFor="">Input user detail to find user</label>
              <input
                type="text"
                placeholder="User Email"
                className="px-3 py1.5 border border-black outline-none"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            {userDetails[0]?.email && (
              <div className="flex flex-col gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                <p>First Name: {userDetails[0]?.firstName}</p>
                <p>Last Name: {userDetails[0]?.lastName}</p>
                <p>Email: {userDetails[0]?.email}</p>
                <Link to={`/reward/${userDetails[0]?.email}`}>
                  <button className="bg-[#359602] w-[100%] text-white text-lg px-2 py-1 my-3 rounded-[10px]">
                    Reward
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardUser;
