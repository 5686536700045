import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import axios from "axios";

function Reward() {
  const [rewardCategory, setRewardCategory] = useState("");
  // const [referralInputData, setreferralInputData] = useState({
  //   refreeName: "",
  //   refreeEmail: "",
  //   amount: "",
  // });
  const [giftsOffersInputData, setGiftsOffersInputData] = useState({
    giftOfferName: "",
    earned: "",
    expires: "",
  });
  const [ft9jaMerchInputData, setFt9jaMerchInputData] = useState({
    merchName: "",
    method: "",
    quantity:""
  });
  const [heroStipendInputData, setHeroStipendInputData] = useState({
    year:"",
    month:"",
    amount:""
  })
  // const [referrals, setReferrals] = useState([]);
  // const [rewardedReferrals, setRewardedReferrals] = useState([]);
  const location = useLocation();
  const pathId = location.pathname.split("/")[2];

  const navigate = useNavigate();

  // const filteredReferrals = referrals.filter(
  //   (obj) => !rewardedReferrals.some((obj1) => obj1.refreeEmail === obj.email)
  // );

  // console.log(giftsOffersInputData);

  // const getRewardedReferrals = useCallback(async (refererEmail) => {
  //   try {
  //     const res = await axios.get(
  //       `https://hero-backend-july10-72654a654b93.herokuapp.com/api/referral-benefit/${refererEmail}`
  //     );
  //     // console.log(res.data);
  //     setRewardedReferrals(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // const getReferrals = useCallback(async (referralCode) => {
  //   try {
  //     const res = await axios.get(
  //       `https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth/referrals/${referralCode}`
  //     );
  //     // console.log(res.data);
  //     setReferrals(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const res = await axios.get(
  //         `https://hero-backend-july10-72654a654b93.herokuapp.com/api/auth/${pathId}`
  //       );
  //       // console.log(res.data.referralCode);
  //       // getReferrals(res.data.referralCode);
  //       // getRewardedReferrals(pathId);
  //       // // setUserDetails(res.data);
  //     } catch (error) {}
  //   };
  //   getUser();
  // }, [pathId]);

  // const handleReferralSubmit = async (e) => {
  //   e.preventDefault();
  //   const refererEmail = pathId;
  //   const referee = referralInputData.refreeName;
  //   const refreeEmail = referralInputData.refreeEmail;
  //   const amount = referralInputData.amount;
  //   const date = new Date().toLocaleDateString("en-GB");

  //   const post = {
  //     refererEmail,
  //     referee,
  //     refreeEmail,
  //     amount,
  //     date,
  //   };
  //   // console.log(post)

  //   try {
  //     const res = await axios.post(
  //       "https://hero-backend-july10-72654a654b93.herokuapp.com/api/referral-benefit",
  //       post
  //     );

  //     console.log(res);
  //     alert(res.data.message);
  //     window.location.reload();
  //   } catch (error) {
  //     alert("Please make sure all fields have been filled out...");
  //   }
  // };

  const handleGiftsOffersSubmit = async (e) => {
    e.preventDefault();
    const userEmail = pathId;
    const giftOfferName = giftsOffersInputData.giftOfferName;
    const earned = giftsOffersInputData.earned;
    const expires = giftsOffersInputData.expires;

    const post = {
      userEmail,
      giftOfferName,
      earned,
      expires,
    };
    // console.log(post)

    try {
      const res = await axios.post(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/gifts-offers-benefit",
        post
      );

      console.log(res);
      alert(res.data.message);
      window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
    }
  };

  const handleFt9jaMerchSubmit = async (e) => {
    e.preventDefault();
    const userEmail = pathId;
    const merchName = ft9jaMerchInputData.merchName;
    const method = ft9jaMerchInputData.method;
    const quantity = ft9jaMerchInputData.quantity;
    const date = new Date().toLocaleDateString("en-GB");

    const post = {
      userEmail,
      merchName,
      method,
      date,
      quantity
    };
    // console.log(post)

    try {
      const res = await axios.post(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/ft9ja-merch-benefit",
        post
      );

      console.log(res);
      alert(res.data.message);
      window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
    }
  };

  const handleHeroStipendSubmit = async (e) => {
    e.preventDefault();
    const userEmail = pathId;
    const year = heroStipendInputData.year;
    const month = heroStipendInputData.month;
    const amount = heroStipendInputData.amount;
    const date = new Date().toLocaleDateString("en-GB");

    const post = {
      userEmail,
      year,
      month,
      amount,
      date,
    };
    // console.log(post)

    try {
      const res = await axios.post(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/hero-stipend-benefit",
        post
      );

      console.log(res);
      alert(res.data.message);
      window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
        <button onClick={() => navigate(-1)} className='mr-auto ml-5 bg-[#FFA500] py-2 px-4 rounded-[100px]'>Go Back</button>
          <h1 className="text-xl">Reward this user: {pathId}</h1>
          <div className="md:w-[85%] w-[90%] mx-auto md:mx-0 flex flex-col items-center justify-center ">
            <div className="flex flex-col mt-10">
              <label htmlFor="" className="mb-2">
                Reward Category
              </label>
              <select
                name=""
                id=""
                className="px-3 py-1.5 border border-black outline-none"
                value={rewardCategory}
                onChange={(e) => setRewardCategory(e.target.value)}
              >
                <option value="" disabled selected>
                  Select Reward Category
                </option>
                <option value="hero-stip">Hero Stipend</option>
                {/* <option value="referral-comm">Referral Commission</option> */}
                <option value="merch">Ft9ja Merch</option>
                <option value="gifts-offers">Gifts & Offers</option>
              </select>
            </div>

            {rewardCategory === "hero-stip" && (
              <div className="mt-10 flex flex-col gap-5">
                <div className="flex flex-col">
                  <label htmlFor="">Year</label>
                  <input
                    type="text"
                    className="px-3 py-1.5 border border-black outline-none"
                    onChange={e => setHeroStipendInputData({...heroStipendInputData, year: e.target.value})}
                    placeholder="Eg: 2023"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Month</label>
                  <select
                    name=""
                    id=""
                    className="px-3 py-1.5 border border-black outline-none"
                    onChange={e => setHeroStipendInputData({...heroStipendInputData, month: e.target.value})}
                  >
                    <option value="" disabled selected>
                      Choose Month
                    </option>
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Amount in Naira</label>
                  <input
                    type="text"
                    className="px-3 py-1.5 border border-black outline-none"
                    onChange={e => setHeroStipendInputData({...heroStipendInputData, amount: e.target.value})}
                    placeholder="Eg: 10000"
                  />
                </div>
                <button
                  onClick={handleHeroStipendSubmit}
                  className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-1.5 w-[303px] px-3 rounded-[10px]"
                >
                  Submit
                </button>
              </div>
            )}

            {rewardCategory === "merch" && (
              <div className="mt-10 flex flex-col gap-5">
                <div className="flex flex-col">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Eg: T-shirt"
                    onChange={(e) =>
                      setFt9jaMerchInputData({
                        ...ft9jaMerchInputData,
                        merchName: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Method</label>
                  <input
                    type="text"
                    placeholder="Eg: Referral commission"
                    onChange={(e) =>
                      setFt9jaMerchInputData({
                        ...ft9jaMerchInputData,
                        method: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Quantity</label>
                  <input
                    type="text"
                    placeholder="Eg: 10"
                    onChange={(e) =>
                      setFt9jaMerchInputData({
                        ...ft9jaMerchInputData,
                        quantity: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <button
                  onClick={handleFt9jaMerchSubmit}
                  className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-1.5 w-[303px] px-3 rounded-[10px]"
                >
                  Submit
                </button>
              </div>
            )}

            {rewardCategory === "gifts-offers" && (
              <div className="mt-10 flex flex-col gap-5">
                <div className="flex flex-col">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Eg: Trip to Dubai"
                    onChange={(e) =>
                      setGiftsOffersInputData({
                        ...giftsOffersInputData,
                        giftOfferName: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Earned</label>
                  <input
                    type="text"
                    placeholder="Eg: Points Redemption"
                    onChange={(e) =>
                      setGiftsOffersInputData({
                        ...giftsOffersInputData,
                        earned: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Expires On</label>
                  <input
                    type="text"
                    placeholder="Eg: Friday, July 27, 2023"
                    onChange={(e) =>
                      setGiftsOffersInputData({
                        ...giftsOffersInputData,
                        expires: e.target.value,
                      })
                    }
                    className="px-3 py-1.5 border border-black outline-none"
                  />
                </div>
                <button
                  onClick={handleGiftsOffersSubmit}
                  className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-1.5 w-[303px] px-3 rounded-[10px]"
                >
                  Submit
                </button>
              </div>
            )}

            {/* {rewardCategory === "referral-comm" && (
              <div className="w-[100%] flex flex-col gap-5">
                <div className="flex items-center justify-between mt-5">
                  <h1>Unrewarded Referrals: {filteredReferrals.length}</h1>
                  <h1>Total Referrals: {referrals.length}</h1>
                </div>
                <div className="flex gap-3">
                  {filteredReferrals?.map((ref) => (
                    <div className="flex flex-col gap-3 w-[90%] md:w-[25%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                      <p>First Name: {ref.firstName}</p>
                      <p>Last Name: {ref.lastName}</p>
                      <p>Email: {ref.email}</p>
                    </div>
                  ))}
                </div>
                <div className="md:w-[30%] w-[90%] mx-auto mt-5 flex flex-col gap-5">
                  <div className="flex flex-col">
                    <label htmlFor="">Referee Name</label>
                    <input
                      type="text"
                      placeholder="Eg: John Smith"
                      onChange={(e) =>
                        setreferralInputData({
                          ...referralInputData,
                          refreeName: e.target.value,
                        })
                      }
                      className="px-3 py-1.5 border border-black outline-none"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="">Refree Email</label>
                    <input
                      type="text"
                      placeholder="Eg: john@smith.com"
                      onChange={(e) =>
                        setreferralInputData({
                          ...referralInputData,
                          refreeEmail: e.target.value,
                        })
                      }
                      className="px-3 py-1.5 border border-black outline-none"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="">Amount</label>
                    <input
                      type="text"
                      placeholder="Eg: 10000"
                      onChange={(e) =>
                        setreferralInputData({
                          ...referralInputData,
                          amount: e.target.value,
                        })
                      }
                      className="px-3 py-1.5 border border-black outline-none"
                    />
                  </div>
                  <button
                    onClick={handleReferralSubmit}
                    className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[100%] px-3 rounded-[10px]"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reward;
