import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";

function Benefit() {
  const [benefits, setBenefits] = useState([]);
  // console.log(benefits);

  useEffect(() => {
    const getBenefits = async () => {
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/benefit/benefits"
        );
        setBenefits(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBenefits();
  }, []);

  const handleDelete = async (pathId) => {
    try {
      const res = await axios.delete(
        `https://hero-backend-july10-72654a654b93.herokuapp.com/api/benefit/benefits/${pathId}`
      );
      alert(res.data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Benefits
          </h1>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {benefits?.map((benefit) => (
              <div
                key={benefit._id}
                className="flex gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
              >
                 <video
                  controls
                  className="w-[150px] h-[150px] rounded-lg"
                  src={benefit.fileUrl}
                  poster={benefit.fileUrl}
                  alt=""
                />
                <div className="w-[80%] relative">
                  <h1 className="font-bold text-lg">
                    Title: <span className="font-normal">{benefit.title}</span>
                  </h1>
                  <p className="font-bold">
                    Description:{" "}
                    <span className="font-normal">{benefit.description}</span>
                  </p>
                  <button
                    onClick={() => handleDelete(benefit._id)}
                    className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] absolute right-0 bottom-0"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
          <Link to="/benefit/add-benefit">
            <button className=" md:hidden fixed bottom-5 left-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Add Benefit
            </button>
          </Link>
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Benefit;
