import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SendingDialog from "../components/SendingDialog";

function AddQuiz() {
  const state = useLocation().state;
  const [quiz, setQuiz] = useState({
    title: state?.title || "",
    year: state?.year || "",
    month: state?.month || "",
    question: state?.question || "",
    answer: state?.answer || "",
  });

  const [options, setOptions] = useState(state?.choices || []);
  const [isOpen, setIsOpen] = useState(false);
  // console.log(options)

  const navigate = useNavigate();

  function handleChoices(e) {
    setOptions(e.target.value.split(","));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true)
    const title = quiz.title;
    const year = quiz.year;
    const month = quiz.month;
    const question = quiz.question;
    const answer = quiz.answer;
    const choices = options;

    const post = { title, year, month, question, answer, choices };
    console.log(post);

    try {
      state
        ? await axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes/${state._id}`, post)
        : await axios.post("https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz", post);

      setIsOpen(false)
      alert("Quiz has been created successfully");
      state ? navigate(`/quiz/${state._id}`) : window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="md:w-[85%] w-[90%] mx-auto md:mx-0 flex items-center justify-center ">
          <form action="" className="md:w-[80%] w-[100%] flex flex-col gap-5">
            <div className="flex flex-col">
              <label htmlFor="">Title</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                value={quiz.title}
                onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Year</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                value={quiz.year}
                onChange={(e) => setQuiz({ ...quiz, year: e.target.value })}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Month</label>
              <select
                name=""
                id=""
                value={quiz.month}
                className="px-3 py-1.5 border border-black outline-none"
                onChange={(e) => setQuiz({ ...quiz, month: e.target.value })}
              >
                <option value="" disabled selected>
                  Choose Month
                </option>
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Question</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                value={quiz.question}
                onChange={(e) => setQuiz({ ...quiz, question: e.target.value })}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Answer</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                value={quiz.answer}
                onChange={(e) => setQuiz({ ...quiz, answer: e.target.value })}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Choices</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                placeholder="Eg: rock,paper,scissors"
                value={options.toString()}
                onChange={handleChoices}
              />
            </div>
            <button
              onClick={(e) => handleSubmit(e)}
              className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
            >
              Submit
            </button>
          </form>
          <SendingDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          {/* <Link to="/quiz/add-quiz">
            <button className=" md:hidden fixed bottom-5 left-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Add Quiz
            </button>
          </Link> */}
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddQuiz;
