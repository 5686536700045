import React from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();
  const pathId = location.pathname.split("/")[1];
  // console.log(pathId)

  return (
    <div className="hidden w-[15%] h-[100%] bg-white md:flex flex-col justify-between drop-shadow-md pt-10 px-5">
      <div className="flex flex-col gap-10 w-[100%] cursor-pointer">
        <Link to="/home">
          <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
            Go To Home
          </p>
        </Link>
        {pathId !== "reward" && pathId !== "notification" && (
          <Link to={`/${pathId}`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              All{" "}
              {pathId === "learning"
                ? "Learning"
                : pathId === "quiz"
                ? "Quizes"
                : pathId === "benefit"
                ? "Benefits"
                : pathId === "advocacy"
                ? "Advocates"
                : pathId === "users"
                ? "Users"
                : "Activities"}
            </p>
          </Link>
        )}
        {pathId !== "reward" && pathId !== "notification" && pathId !== "users" && (
          <Link to={`/${pathId}/add-${pathId}`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Add{" "}
              {pathId === "learning"
                ? "Learning"
                : pathId === "quiz"
                ? "Quiz"
                : pathId === "benefit"
                ? "Benefit"
                : pathId === "advocacy"
                ? "Advocates"
                : "Activity"}
            </p>
          </Link>
        )}
        {pathId === "activities" && (
          <Link to={`/${pathId}/user-activities-proof`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Proof of Activities
            </p>
          </Link>
        )}
        {pathId === "benefit" && (
          <Link to={`/${pathId}/reward-user`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Reward User
            </p>
          </Link>
        )}
        {pathId === "benefit" && (
          <Link to={`/${pathId}/withdrawal-requests`}>
            <p className="text-white md:text-lg px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Withdrawal Requests
            </p>
          </Link>
        )}
        {pathId === "benefit" && (
          <Link to={`/${pathId}/rewards`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Rewards
            </p>
          </Link>
        )}
        {pathId === "quiz" && (
          <Link to={`/${pathId}/quiz-results`}>
            <p className="text-white md:text-xl px-1.5 py-2 bg-[#359602] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Quiz Results
            </p>
          </Link>
        )}
      </div>
      <Link to="/" className="mb-10">
        <p className="text-black md:text-xl px-1.5 text-center py-2 bg-[#FFA500] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
          Log out
        </p>
      </Link>
    </div>
  );
}

export default Sidebar;
