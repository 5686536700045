import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

function Learning() {
  const [learnings, setLearnings] = useState([]);
  const [filteredLearning, setFilteredLearnings] = useState([]);
  const [videoFilterParams, setVideoFilterParams] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(learnings);

  useEffect(() => {
    const getLearnings = async () => {
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/learning/learnings"
        );
        setLearnings(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  const handleDelete = async (pathId, pathTitle) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `https://hero-backend-july10-72654a654b93.herokuapp.com/api/learning/learnings/${pathId}`
      );
      const res1 = await axios.delete(
        `https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-in-progress-video/user-in-progress-video/${pathTitle}`
      );
      const res2 = await axios.delete(
        `https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-completed-video/user-completed-video/${pathTitle}`
      );
      alert(res.data);
      if(res1.status === 200 || res2.status === 200){
        setLoading(false)
      }
      // console.log(res1);
      // console.log(res2);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const filteredResults = learnings.filter(
      (i) => i.category === videoFilterParams
    );
    setFilteredLearnings(filteredResults);
  }, [learnings, videoFilterParams]);

  return (
    <div className="h-[100vh] relative">
      {loading && (
        <div
          onClick={() => setLoading(false)}
          className="absolute left-0 top-0 z-10 flex items-center justify-center bg-gray-600/80 w-[100vw] h-[100vh]"
        >
          <div className="bg-white w-[20%] h-[20%] flex items-center justify-center rounded-lg">
            <h1 className="text-center font-bold text-[2rem]">Please Wait...</h1>
          </div>
        </div>
      )}
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Learnings
          </h1>
          <div className="flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10">
            <div>
              <p>Filter by category</p>
              <select
                name=""
                id=""
                className="outline-none bg-[#359602] text-white p-2 rounded-xl"
                onChange={(e) => setVideoFilterParams(e.target.value)}
              >
                <option value="" className="bg-white text-black">
                  Select Video Category
                </option>
                <option
                  value="Technical Analysis - Part 1"
                  className="bg-white text-black"
                >
                  Technical Analysis - Part 1
                </option>
                <option
                  value="Technical Analysis - Part 2"
                  className="bg-white text-black"
                >
                  Technical Analysis - Part 2
                </option>
                <option
                  value="Fundamental Analysis"
                  className="bg-white text-black"
                >
                  Fundamental Analysis
                </option>
                <option
                  value="Fundamental Analysis - Part 2"
                  className="bg-white text-black"
                >
                  Fundamental Analysis - Part 2
                </option>
                <option
                  value="Why FT9ja?"
                  className="bg-white text-black"
                >
                  Why FT9ja?
                </option>
                <option value="Risk Management" className="bg-white text-black">
                  Risk Management
                </option>
                <option
                  value="Trading Psychology"
                  className="bg-white text-black"
                >
                  Trading Psychology
                </option>
              </select>
            </div>
          </div>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {filteredLearning.length > 0 ? (
              filteredLearning.map((learning) => (
                <div
                  key={learning._id}
                  className="flex gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                >
                  <ReactPlayer
                    controls
                    url={learning.fileUrl}
                    width={150}
                    height={150}
                  />
                  <div className="w-[80%] relative">
                    <h1 className="font-bold text-lg">
                      Title:{" "}
                      <span className="font-normal">{learning.title}</span>
                    </h1>
                    <p className="font-bold">
                      Category:{" "}
                      <span className="font-normal">{learning.category}</span>
                    </p>
                    <p className="font-bold ">
                      Description:{" "}
                      <span className="font-normal">
                        {learning.description}
                      </span>
                    </p>
                    <button
                      onClick={() => handleDelete(learning._id, learning.title)}
                      className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] absolute right-0 bottom-0"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ) : filteredLearning.length < 1 && videoFilterParams !== "" ? (
              <div></div>
            ) : (
              learnings?.map((learning) => (
                <div
                  key={learning._id}
                  className="flex gap-3 w-[90%] md:w-[48.3%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg"
                >
                  <ReactPlayer
                    controls
                    url={learning.fileUrl}
                    width={150}
                    height={150}
                  />
                  <div className="w-[80%] relative">
                    <h1 className="font-bold text-lg">
                      Title:{" "}
                      <span className="font-normal">{learning.title}</span>
                    </h1>
                    <p className="font-bold">
                      Category:{" "}
                      <span className="font-normal">{learning.category}</span>
                    </p>
                    <p className="font-bold ">
                      Description:{" "}
                      <span className="font-normal">
                        {learning.description}
                      </span>
                    </p>
                    <button
                      onClick={() => handleDelete(learning._id, learning.title)}
                      className="bg-black text-white text-lg px-2 py-1 my-3 rounded-[10px] absolute right-0 bottom-0"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          <Link to="/learning/add-learning">
            <button className=" md:hidden fixed bottom-5 left-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Add Learning
            </button>
          </Link>
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Learning;
