import { storage } from "../firebase";
import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { Line } from "rc-progress";
import { Link } from "react-router-dom";
import SendingDialog from "../components/SendingDialog";

function AddBenefit() {
  const [benefit, setBenefit] = useState({
    title: "",
    description: "",
  });
  const [fileURL, setFileURL] = useState();

  const [progress, setProgress] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = async (e) => {
    const file = e.target.files[0];

    try {
      const storageRef = ref(storage, `benefit/${file.name}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);

          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log("No upload yet");
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFileURL(downloadURL);
            console.log("File available at", downloadURL);
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true)
    const fileUrl = fileURL;
    const title = benefit.title;
    const description = benefit.description;

    const post = { fileUrl, title, description };
    // console.log(post)

    try {
      const res = await axios.post("https://hero-backend-july10-72654a654b93.herokuapp.com/api/benefit", post);
      setIsOpen(false)
      alert(res.data.message);
      window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
      window.location.reload();
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="md:w-[85%] w-[90%] flex items-center mx-auto md:mx-0 justify-center ">
          <form action="" className="md:w-[80%] w-[100%] flex flex-col gap-5">
            <input
              className="outline-none"
              type="File"
              onChange={handleChange}
            />
            {progress && (
              <div className="mx-auto flex items-center gap-2 justify-center mt-5">
                <Line
                  percent={progress}
                  strokeWidth={2}
                  strokeColor="#359602"
                  className="md:w-[100%] w-[80%]"
                />
                <span>{Math.floor(progress) + "%"}</span>
              </div>
            )}
            <div className="flex flex-col">
              <label htmlFor="">Title</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                onChange={(e) =>
                  setBenefit({ ...benefit, title: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Description</label>
              <textarea
                className="px-3 py-1.5 border border-black outline-none"
                name=""
                id=""
                cols="30"
                rows="5"
                onChange={(e) =>
                  setBenefit({ ...benefit, description: e.target.value })
                }
              ></textarea>
            </div>
            <button
              onClick={(e) => handleSubmit(e)}
              className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
            >
              Submit
            </button>
          </form>
          <SendingDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddBenefit;
