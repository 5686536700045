import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import axios from 'axios';
import MyDialog2 from '../components/MyDialog2';
import { v4 as uuidv4 } from "uuid";

const addToNumberString = (intStr, value) => {
    let originalLength = intStr.length
    let sumInt = Number(intStr) + value;
    let sumIntStr = String(sumInt);

    return sumIntStr.padStart(originalLength, '0');
};

function Rewards() {
    const [rewardCategory, setRewardCategory] = useState("");
    const [allHeroStipend, setAllHeroStipend] = useState([])
    const [filteredAllHeroStipend, setFilteredAllHeroStipend] = useState([])
    const [allFt9jaMerch, setAllFt9jaMerch] = useState([])
    const [filteredAllFt9jaMerch, setFilteredAllFt9jaMerch] = useState([])
    const [allGiftsOffers, setAllGiftsOffers] = useState([])
    const [filteredAllGiftsOffers, setFilteredAllGiftsOffers] = useState([])
    const [filterParams, setFilterParams] = useState({
        month: "",
        year: "",
        email: "",
    })
    const [filterBy, setFilterBy] = useState("")
    const [message, setMessage] = useState("");
    const [transferCode, setTransferCode] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [withdrawalId, setWithdrawlId] = useState("")
    // console.log(filteredAllHeroStipend)
    // console.log(allFt9jaMerch)
    // console.log(allGiftsOffers)

    useEffect(() => {
        if (rewardCategory === "hero-stip") {
            axios
                .get("https://hero-backend-july10-72654a654b93.herokuapp.com/api/hero-stipend-benefit/hero-stipends-rewards/")
                .then((res) => {
                    // console.log(res.data);
                    setAllHeroStipend(res.data);
                    // const all = res.data;
                });
        } else if (rewardCategory === "merch") {
            axios
                .get("https://hero-backend-july10-72654a654b93.herokuapp.com/api/ft9ja-merch-benefit/ft9ja-merch-benefits-rewards")
                .then((res) => {
                    // console.log(res.data);
                    setAllFt9jaMerch(res.data);
                    // const all = res.data;
                });
        } else if (rewardCategory === "gifts-offers") {
            axios
                .get("https://hero-backend-july10-72654a654b93.herokuapp.com/api/gifts-offers-benefit/gift-and-offers-benefits-rewards")
                .then((res) => {
                    // console.log(res.data);
                    setAllGiftsOffers(res.data);
                    // const all = res.data;
                });
        }
    }, [rewardCategory]);

    useEffect(() => {
        if (filterParams.email !== "" && rewardCategory === "hero-stip") {
            const filtered = allHeroStipend.filter(item => item.userEmail === filterParams.email)
            //    console.log(filtered)
            setFilteredAllHeroStipend(filtered)
        } else if (filterParams.month !== "select" && filterParams.year !== "" && rewardCategory === "hero-stip") {
            const filtered = allHeroStipend.filter(item => item.month === filterParams.month && item.year === filterParams.year)
            // console.log(filtered)
            setFilteredAllHeroStipend(filtered)
        } else if (filterParams.email !== "" && rewardCategory === "merch") {
            const filtered = allFt9jaMerch.filter(item => item.userEmail === filterParams.email)
            // console.log(filtered)
            setFilteredAllFt9jaMerch(filtered)
        } else if (filterParams.email !== "" && rewardCategory === "gifts-offers") {
            const filtered = allGiftsOffers.filter(item => item.userEmail === filterParams.email)
            // console.log(filtered)
            setFilteredAllGiftsOffers(filtered)
        }
    }, [filterParams.email, rewardCategory, allHeroStipend, filterParams.month, filterParams.year, allFt9jaMerch, allGiftsOffers])

    const verifyDetails = async (
        customerDataForVerification,
        filterBankForVerification,
        amount,
        id
    ) => {
        setIsOpen(true)
        setMessage("Verifying Account Details, Please Wait...");
        // const account_number = parseInt(customerDataForVerification.Account_Number)
        const account_number = addToNumberString(customerDataForVerification, 0)

        try {
            axios({
                method: "GET",
                url: `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${filterBankForVerification}`,
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log(response.data.data.account_name);
                    setMessage(response.data.message);
                    createRecipient(
                        account_number,
                        filterBankForVerification,
                        response.data.data.account_name,
                        amount,
                        id
                    );
                })
                .catch((error) => {
                    console.error(error);
                    setMessage(
                        "Failed to verify that this account exists, please check the account details and try again"
                    );
                });
        } catch (error) { }
    };

    const createRecipient = async (acctNum, bankCode, acctName, amount, id) => {
        setIsOpen(true)
        setMessage("Creating Recipient, Please Wait...");
        try {
          axios({
            method: "POST",
            url: "https://api.paystack.co/transferrecipient",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
              "Content-Type": "application/json",
            },
            data: {
              type: "nuban",
              name: acctName,
              account_number: acctNum,
              bank_code: bankCode,
              currency: "NGN",
            },
          })
            .then((response) => {
              console.log(response.data.data.recipient_code);
              setMessage(response.data.message);
              transferMoney(
                amount,
                response.data.data.recipient_code,
                id
              );
            })
            .catch((error) => {
              console.error(error);
              setMessage(
                "Couldn't create a recipient for this payout, please check the account details and try again or refresh the page and try again"
              );
            });
        } catch (error) { }
      };

      const transferMoney = async (
        amount,
        recipientCodeForTransfer,
        _id
      ) => {
        // console.log(i);
        setMessage("Initiating Transfer, Please Wait...");
        const id = uuidv4();
        const headers = {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRETE_KEY}`,
          "Content-Type": "application/json",
        };
    
        const url = "https://api.paystack.co/transfer";
    
        const data = JSON.stringify({
          source: "balance",
          amount: amount * 100,
          reference: id,
          recipient: recipientCodeForTransfer,
          reason: "Commission",
        });
    
        try {
          axios
            .post(url, data, { headers })
            .then((response) => {
              console.log(response);
              setTransferCode(response.data.data.transfer_code);
              setWithdrawlId(_id)
              setMessage(response.data.message);
            })
            .catch((error) => {
              console.log(error);
              setMessage(error.response.data.message);
              // alert(error.response.data.message);
            });
        } catch (error) { }
      };

    function handleHeroStipendApprove(email, amount, id) {
        // console.log(id)
        axios.get(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-banking-info/${email}`).then(res => {
            console.log(res)
            verifyDetails(res.data[0].bankAcct, res.data[0].bankCode, amount, id)

        })
    }

    function handleStipendNoApprove(id) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/hero-stipend-benefit/hero-stipends-rewards/${id}`, {
            status: "declined"
        }).then(res => {
            console.log(res)
            alert("Hero stipend for this user has been updated to declined...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    function handleFt9jaMerchApprove(id) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/ft9ja-merch-benefit/ft9ja-merch-benefits-rewards/${id}`, {
            status: "approved"
        }).then(res => {
            console.log(res)
            alert("FT9ja merch for this user has been updated to declined...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    function handleMerchNoApprove(id) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/ft9ja-merch-benefit/ft9ja-merch-benefits-rewards/${id}`, {
            status: "declined"
        }).then(res => {
            console.log(res)
            alert("FT9ja merch for this user has been updated to declined...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    function handleGiftsOffersApprove(id) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/gifts-offers-benefit/gift-and-offers-benefits-rewards/${id}`, {
            status: "approved"
        }).then(res => {
            console.log(res)
            alert("Gifts and Offers for this user has been updated to approved...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    function handleoffersNoApprove(id) {
        axios.put(`https://hero-backend-july10-72654a654b93.herokuapp.com/api/gifts-offers-benefit/gift-and-offers-benefits-rewards/${id}`, {
            status: "declined"
        }).then(res => {
            console.log(res)
            alert("Gifts and Offers for this user has been updated to declined...")
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
                    <h1 className="mb-5 text-4xl font-bold text-[#359602] text-center">
                        Select reward category to see users that have been rewarded.
                    </h1>
                    <div className="md:w-[85%] w-[95%] mx-auto md:mx-0 flex flex-col items-center justify-center ">
                        <div className="flex flex-col mt-10">
                            <label htmlFor="" className="mb-2">
                                Reward Category
                            </label>
                            <select
                                name=""
                                id=""
                                className="px-3 py-1.5 border border-black outline-none"
                                value={rewardCategory}
                                onChange={(e) => setRewardCategory(e.target.value)}
                            >
                                <option value="" disabled selected>
                                    Select Reward Category
                                </option>
                                <option value="hero-stip">Hero Stipend</option>
                                {/* <option value="referral-comm">Referral Commission</option> */}
                                <option value="merch">Ft9ja Merch</option>
                                <option value="gifts-offers">Gifts & Offers</option>
                            </select>
                        </div>

                        {rewardCategory === "hero-stip" && allHeroStipend.length > 0 && <div className='mt-10 lg:w-[70%]'>
                            <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10 mx-auto'>
                                <div>
                                    <p>Choose filter option</p>
                                    <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterBy(e.target.value)}>
                                        <option value="">Filter By:</option>
                                        <option value="email">Email</option>
                                        <option value="year-month">Year and Month</option>
                                    </select>
                                </div>
                                {filterBy === "year-month" && <div className='flex flex-col lg:flex-row items-center lg:gap-10'>
                                    <div>
                                        <p>Filter by month</p>
                                        <select name="" id="" className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, month: e.target.value, email: "" })}>
                                            <option value="select">Select Month</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                    </div>
                                    <div>
                                        <p>Filter by Year</p>
                                        <input type="text" placeholder='Eg. 2023' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, year: e.target.value, email: "" })} />
                                    </div>

                                </div>}
                                {filterBy === "email" && <div>
                                    <p>Filter by Email</p>
                                    <input type="text" placeholder='Eg. test0@test0.com' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, email: e.target.value })} />
                                </div>}
                            </div>
                            <div className='grid grid-cols-2 gap-5 mt-10'>
                                {filteredAllHeroStipend.length > 0 ? filteredAllHeroStipend.map(stip => (<div key={stip._id} className="flex flex-col gap-3 shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                    <p>User Email: <span>{stip.userEmail}</span></p>
                                    <p>Month: <span>{stip.month}</span></p>
                                    <p>Year: <span>{stip.year}</span></p>
                                    <p>Amount: <span>{stip.amount}</span></p>
                                    <p>Status: <span>{stip.status}</span></p>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleHeroStipendApprove(stip.userEmail, stip.amount, stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleStipendNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                </div>)) : filteredAllHeroStipend.length < 1 && filterParams.email !== "" && filterBy === "email" ? <div></div> : filteredAllHeroStipend.length < 1 && filterBy === "year-month" && filterParams.month !== "select" && filterParams.year !== "" ? <div></div> : allHeroStipend.map(stip => (
                                    <div key={stip._id} className="flex flex-col gap-3 shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                        <p>User Email: <span>{stip.userEmail}</span></p>
                                        <p>Month: <span>{stip.month}</span></p>
                                        <p>Year: <span>{stip.year}</span></p>
                                        <p>Amount: <span>{stip.amount}</span></p>
                                        <p>Status: <span>{stip.status}</span></p>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleHeroStipendApprove(stip.userEmail, stip.amount, stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleStipendNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                    </div>
                                ))}

                            </div>
                        </div>}

                        {rewardCategory === "merch" && allFt9jaMerch.length > 0 && <div className='mt-10 lg:w-[70%]'>
                            <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10 mx-auto'>
                                <div>
                                    <p>Filter by Email</p>
                                    <input type="text" placeholder='Eg. test0@test0.com' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, email: e.target.value })} />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-5 mt-10'>
                                {filteredAllFt9jaMerch.length > 0 ? filteredAllFt9jaMerch.map(stip => (<div key={stip._id} className="flex flex-col gap-3 w-[90%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                    <p>User Email: <span>{stip.userEmail}</span></p>
                                    <p>Earned method: <span>{stip.method}</span></p>
                                    <p>Item: <span>{stip.name}</span></p>
                                    <p>Quantity: <span>{stip.quantity}</span></p>
                                    <p>Date: <span>{stip.date}</span></p>
                                    <p>Status: <span>{stip.status}</span></p>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleFt9jaMerchApprove(stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleMerchNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                </div>)) : filteredAllFt9jaMerch.length < 1 && rewardCategory === "merch" && filterParams.email !== "" ? <div></div> : allFt9jaMerch.map(stip => (
                                    <div key={stip._id} className="flex flex-col gap-3 w-[90%] shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                        <p>User Email: <span>{stip.userEmail}</span></p>
                                        <p>Earned method: <span>{stip.method}</span></p>
                                        <p>Item: <span>{stip.name}</span></p>
                                        <p>Quantity: <span>{stip.quantity}</span></p>
                                        <p>Date: <span>{stip.date}</span></p>
                                        <p>Status: <span>{stip.status}</span></p>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleFt9jaMerchApprove(stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleMerchNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                    </div>
                                ))}

                            </div>
                        </div>}

                        {rewardCategory === "gifts-offers" && allGiftsOffers.length > 0 && <div className='mt-10 lg:w-[70%]'>
                            <div className='flex flex-col lg:flex-row items-center lg:gap-10 gap-5 mb-10 mx-auto'>
                                <div>
                                    <p>Filter by Email</p>
                                    <input type="text" placeholder='Eg. test0@test0.com' className="px-3 py-1.5 border border-black outline-none" onChange={(e) => setFilterParams({ ...filterParams, email: e.target.value })} />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-5 mt-10'>
                                {filteredAllGiftsOffers.length > 0 ? filteredAllGiftsOffers.map(stip => (<div key={stip._id} className="flex flex-col gap-3 w-[90 shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                    <p>User Email: <span>{stip.userEmail}</span></p>
                                    <p>Earned method: <span>{stip.earned}</span></p>
                                    <p>Expires: <span>{stip.expires}</span></p>
                                    <p>Gift: <span>{stip.name}</span></p>
                                    <p>Status: <span>{stip.status}</span></p>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleGiftsOffersApprove(stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                    <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleoffersNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                </div>)) : filteredAllGiftsOffers.length < 1 && rewardCategory === "gifts-offers" && filterParams.email !== "" ? <div></div> : allGiftsOffers.map(stip => (
                                    <div key={stip._id} className="flex flex-col gap-3 w-[90 shadow-xl border border-[#FFA500] px-3 py-3 rounded-lg">
                                        <p>User Email: <span>{stip.userEmail}</span></p>
                                        <p>Earned method: <span>{stip.earned}</span></p>
                                        <p>Expires: <span>{stip.expires}</span></p>
                                        <p>Gift: <span>{stip.name}</span></p>
                                        <p>Status: <span>{stip.status}</span></p>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleGiftsOffersApprove(stip._id)} className="bg-[#359602] text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-600">Approved</button>
                                        <button disabled={stip.status === "approved" || stip.status === "declined"} onClick={() => handleoffersNoApprove(stip._id)} className="bg-black text-white text-lg px-2 py-1 mt-3 rounded-[10px] disabled:bg-gray-800">Not Approved</button>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>
                <MyDialog2
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    message={message}
                    setMessage={setMessage}
                    transferCode={transferCode}
                    setTransferCode={setTransferCode}
                    withdrawalId={withdrawalId}
                />
            </div>
        </div>
    )
}

export default Rewards